import { useEffect, useReducer, useState } from 'react';
//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Button from 'react-bootstrap/Button';
import { IoAdd, IoCamera, IoExpand, IoPricetagOutline } from 'react-icons/io5';
import PlaceHolderItemList from 'components/loader/PlaceHolderItemList';
import { toast } from 'react-toastify';

//Utils
import axios from 'axios';

//Logic
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import OffcanvasManageCoupon from './components/OffcanvasManageCoupon';
import moment from 'moment';

function useCoupons() {

    const [coupons, setCoupons] = useState();
    const [manageCoupon, dispatchManageCoupon] = useOffCanvasReducer();

    const handleAddCoupon = type => {
        axios.post('coupons', {
            type
        })
            .then(res => {
                setCoupons([
                    ...coupons,
                    res.data
                ]);
                toast.success('Coupon aggiunto con successo, compilare di seguito i dati');
                dispatchManageCoupon(res.data);
            });
    }

    const onUpdateCoupon = coupon => {
        setCoupons(coupons.map((p) => {
            if (p.id !== coupon.id) return p;
            return coupon;
        }));
    }

    useEffect(() => {
        axios.get('coupons')
            .then(res => setCoupons(res.data))
    }, []);

    return {
        coupons,
        manageCoupon, dispatchManageCoupon,
        handleAddCoupon,
        onUpdateCoupon
    }
}

function CouponItem({ coupons, coupon, onEditCoupon, isSkeleton }) {

    return (
        <div className="item">
            <div className="icon-box me-05">
                {!coupon.parent_id && <IoPricetagOutline />}
            </div>
            {isSkeleton ? <PlaceHolderItemList /> : <>
                <div className="in">
                    <div>
                        <span>
                            {coupon.code} - {coupon.description}
                        </span>
                        <span class="d-block text-muted">
                            <span>Sconto: {coupon.discount}%</span>
                            <br />
                            <span>Utilizzi: {coupon.n_orders}</span>
                            <br />
                            <span>Scadenza: {coupon.expires_at ? moment(coupon.expires_at).format('DD/MM/yy') : 'NO'} </span>
                        </span>
                    </div>
                    <div style={{ minWidth: '120px', textAlign: 'right' }}>
                        <Button
                            size="sm"
                            className='btn-light'
                            onClick={() => onEditCoupon(coupon)}
                        >
                            Modifica
                        </Button>
                    </div>
                </div>
            </>}
        </div>
    );
}

function AddCoupon({ onClick, title }) {
    return (
        <li className='cursor-pointer' onClick={onClick}>
            <div className="item">
                <div className="icon-box bg-success">
                    <IoAdd />
                </div>
                <div className="in text-success">
                    <div className="fw-500">Aggiungi coupon</div>
                </div>
            </div>
        </li>
    );
}

export default function Coupons() {

    const {
        coupons,
        manageCoupon, dispatchManageCoupon,
        handleAddCoupon,
        onUpdateCoupon
    } = useCoupons();

    const isSkeleton = !coupons;
    const couponsArray = coupons ?? new Array(5).fill({ isSkeleton: true });

    return (
        <>
            <Header pageTitle={'Gestione coupons'} />
            <Capsule>
                <div className={`section ${isSkeleton ? 'no-pointer-events' : ''}`}>
                    <ul className="listview image-listview rounded">
                        <AddCoupon key="add" onClick={() => !isSkeleton && handleAddCoupon()} />
                        {couponsArray.map((coupon, index) => {
                            return (
                                <li key={coupon.id || index} className='rounded'>
                                    <CouponItem
                                        coupon={coupon}
                                        onEditCoupon={dispatchManageCoupon}
                                        coupons={coupons}
                                        isSkeleton={coupon.isSkeleton}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </Capsule>
            <OffcanvasManageCoupon
                manageCoupon={manageCoupon}
                handleClose={() => dispatchManageCoupon('CLOSE')}
                coupons={coupons}
                onUpdateCoupon={onUpdateCoupon}
            />
        </>

    );
}
