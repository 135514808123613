import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import { ref } from "firebase/storage";
import useCartItems from "hooks/useCartItems";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import useOrder from "hooks/useOrder";
import useUpload from "hooks/useUpload";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUploads } from "store/selectors";
import { createUUID, getFileExtension } from "utils/helpers";


export default function usePlan() {

    const navigate = useNavigate();
    const { user } = useAuthValue();
    const [products, setProducts] = useState();
    const [showOffCanvasServiceInfo, setShowOffCanvasServiceInfo] = useOffCanvasReducer();
    const [completedOrderData, setCompletedOrderData] = useState();
    const [instructions, setInstructions] = useState('');
    const ukey = useMemo(() => createUUID(), []);
    const uploads = useSelector(selectUploads);
    const plan = uploads[ukey] || null;

    const {
        placeOrder,
        isInProgress,
        isCartInProgress,
        couponData,
        applyCoupon
    } = useOrder();

    useEffect(() => {
        axios.get('/products', {
            params: {
                type: 'PLAN',
                organization_id: user.agent.getSelectedOrganization().id
            }
        })
        .then(res => setProducts(res.data));
    }, []);

    const handleSelectProduct = (product_id) => {
        setProducts(products.map(p => {
            p.is_selected = p.id === product_id;
            return p;
        }));
    }

    const cartItems = useCartItems(products);


    const handleChangeInstructions = (e) => {
        setInstructions(e.target.value);
    }

    const handlePlaceOrder = () => {
        placeOrder({
            organization_id: user.agent.getSelectedOrganization().id,
            products: cartItems.products.map(p => ({ id: p.id, is_priority: false })),
            with_plan: true,
            metadata: {
                plan: {
                    file_path: Object.values(plan)[0].path,
                    instructions: instructions || 'Nessuna nota fornita'
                }
            }
        })
        .then(res => setCompletedOrderData(res.order))
    }

    const handleCloseModalConfirm = () => {
        navigate(`/orders?type=PLAN&action=showo&order_id=${completedOrderData.id}`);
    }

    return {
        products,
        handleSelectProduct,
        showOffCanvasServiceInfo, setShowOffCanvasServiceInfo,
        cartItems,
        instructions, handleChangeInstructions,
        placeOrder,
        plan,
        isInProgress,
        isCartInProgress,
        handlePlaceOrder,
        completedOrderData,
        handleCloseModalConfirm,
        ukey,
        couponData,
        applyCoupon
    }
}