import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import useOrder from "hooks/useOrder";
import moment from "moment";
import { useState, useEffect, useMemo, useCallback } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { dialogOpen } from "store/actions/dialogBox";

export function useReservationNew() {
    const { buildingId, appointmentId } = useParams();
    const [step, setStep] = useState(appointmentId ? 'calendar' : 'service');
    const [buildingData, setBuildingData] = useState();
    const [products, setProducts] = useState();
    const [photographers, setPhotographers] = useState();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState();
    const [reservationConfirmData, setReservationConfirmData] = useState();
    const [checkoutData, setCheckoutData] = useState({ note: '', agent_on_site: false });

    const navigate = useNavigate();
    const { user } = useAuthValue();
    const { 
        placeOrder, 
        isInProgress, 
        isCartInProgress, 
        cancelOrder, 
        resetPaymentData,
        couponData,
        applyCoupon
    } = useOrder();

    useEffect(() => {
        (async () => {
            let building_id = buildingId;
            let selected_products = [];
            if (appointmentId) {
                const appRes = await axios.get(`appointments/${appointmentId}`);
                setBuildingData(appRes.data.building);
                building_id = appRes.data.building.id;
                selected_products = appRes.data.order.order_items;
            } else {
                const buildRes = await axios.get(`buildings/${buildingId}`, {
                    params: {
                        extract_organization: 1
                    }
                });
                setBuildingData(buildRes.data);
            }

            const productRes = await axios.get('products', { 
                params: { 
                    building_id: building_id
                }
            })

            if (user.is_admin) {
                const photographersRes = await axios.get('users', {
                    params: {
                        role: 'photographer'
                    }
                })
                setPhotographers(photographersRes.data);
            } else {
                setPhotographers([]);
            }
            setProducts(productRes.data.filter(p => {
                const selected_product = selected_products.find(sp => sp.product.id === p.id);
                if (!selected_product) return p;
                p.is_selected = true;
                p.is_priority = selected_product.is_priority;
                return p;
            }));
        })();
    }, []);

    const cartItems = useMemo(() => {
        if (!products) return null;
        const cartProducts = products.filter(p => p.is_selected);
        let total_time = 0;
        let total_price = 0;
        cartProducts.forEach(p => {
            total_time += (p.time || 0);
            total_price += (p.price || 0);
            if (p.is_priority) {
                total_price += p.priority_price;
            }
        });

        return {
            products: cartProducts.sort((a, b) => (a.type < b.type)),
            time: total_time,
            price: total_price,
            price_vat_included: total_price * 1.22
        }
    }, [products]);

    const nextStep = useMemo(() => {
        if (step === 'calendar' && user.is_admin && !selectedTimeSlot) {
            return null
        }
        return () => setStep(step === 'service' ? 'calendar' : 'checkout');
    }, [step, selectedTimeSlot]);

    const handleSelectProduct = (product) => {
        setProducts(products.map(p => {
            if (p.parent_id === product.id) {
                const parentProduct = products.find(pp => pp.id === p.parent_id);
                if (!parentProduct.is_selected) {
                    p.is_selected = false;
                }
            }
            if (product.type === 'PRIMARY') {
                if (p.type === 'PRIMARY' && product.id !== p.id && !product.parent_id) {
                    p.is_selected = false;
                    p.is_priority = false;
                }
                if (p.id === product.id) {
                    p.is_selected = !product.is_selected;
                }
                return p;
            }
            if (p.id === product.id) {
                const value = !product.is_selected;
                p.is_selected = value;
                if (!value) p.is_priority = false;
            }
            return p;
        }));
    }

    const handleSelectProductPriority = (product) => {
        setProducts(products.map(p => {
            if (p.id === product.id) {
                p.is_priority = !product.is_priority;
            }
            return p;
        }));
    }

    const handlePreviusStep = () => {
        resetPaymentData();
        if (step === 'service') return navigate(user.is_admin ? '/admin/calendar' : `/buildings?action=show&id=${buildingData.id}`)
        if (step === 'calendar') return setStep('service');
        //Else is checkout
        return setStep('calendar');
    }

    const completeCheckout = () => {
        placeOrder({
            with_appointments: true,
            building_id: buildingData.id,
            appointment_id: appointmentId,
            products: cartItems.products.map(p => ({
                id: p.id,
                is_priority: !!p.is_priority,
            })),
            photographer_uuid: selectedTimeSlot.photographer_id,
            start_at: selectedTimeSlot.start.toISOString(),
            travel_paid: (selectedTimeSlot?.travel_paid?.km_refund ?? 0) + (selectedTimeSlot?.travel_paid?.toll ?? 0),
            ...checkoutData
        })
        .then(data => {
            setReservationConfirmData(data);
        })
        .catch((error) => {
            error && toast.error(error.response.data.message);
        });
    }

    const handleCloseConfirm = () => {
        const appointment_id = reservationConfirmData.appointment_id;
        navigate(`${user.is_admin ? '/admin' : ''}/calendar/?action=show&id=${appointment_id}&date=${moment(reservationConfirmData.estimated_start_at).format('YYYY-MM-DD')}`);
        setReservationConfirmData();
    }

    const handleChangeCheckoutData = (event) => {
        const { type, name, value, checked } = event.target;
        setCheckoutData({
            ...checkoutData,
            [name]: type === 'checkbox' ? checked : value
        });
    }

    return {
        step, setStep,
        buildingData,
        products,
        photographers,
        cartItems,
        handleSelectProduct, handleSelectProductPriority,
        handlePreviusStep,
        selectedTimeSlot, setSelectedTimeSlot,
        isInProgress,
        isCartInProgress,
        completeCheckout,
        reservationConfirmData,
        handleCloseConfirm,
        checkoutData, handleChangeCheckoutData,
        cancelOrder,
        appointmentId,
        nextStep,
        couponData,
        applyCoupon
    };
}