//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

import useWelcomeAgent from './useWelcomeAgent';

//Assets
import welcomeAgentImage from './../img/welcome-agent.svg';

//components
import Button from 'react-bootstrap/Button';
import OffcanvasAddActivity from '../../../components/offcanvas/add-activity/OffcanvasAddActivity';
import { useAuthValue } from 'context/AuthContext';

function PendingOrganizationItem({ pending, handleConfirmInvite }) {
    return (
        <li>
            {pending.organization.display_name}
            <button className='btn btn-primary btn-sm' onClick={() => handleConfirmInvite(pending.id)}>
                Accetta invito
            </button>
        </li>
    )
}

function WelcomeAgent() {

    const {
        showOffCanvasAdd, setShowOffCanvasAdd,
        handleConfirmInvite,
        handleReloadUser
    } = useWelcomeAgent();

    const { user } = useAuthValue();

    return <>
        <Header show_menu={false} show_logo show_logout />
        <Capsule>
            <div className="login-form mt-2">
                <div className="section">
                    <h1>Crea la tua attività</h1>
                    <p>Per iniziare ad utilizzare l'app <b>crea il profilo della tua attività</b>.</p>
                    <img className="body-image" alt="attività" src={welcomeAgentImage}/>
                        <p><i>oppure</i></p>
                        {user.agent.pendings.length ? <>
                            <p>Visualizza le attività che ti hanno invitato a partecipare:</p>
                            <ul className="listview simple-listview rounded">
                                {user.agent.pendings.map(pending => (
                                    <PendingOrganizationItem 
                                        key={pending.id}
                                        pending={pending} 
                                        handleConfirmInvite={handleConfirmInvite}
                                    />
                                ))}
                            </ul>
                        </> : <p>
                            Se sei il collaboratore di un'attività già esistente richiedi al titolare della tua attività di aggiungerti con la mail e successivamente {' '}
                            <u onClick={handleReloadUser} className="cursor-pointer active">clicca qui</u> per aggiornare.
                        </p>}
                </div>
                <div className="section mt-2 mb-5">
                    <div className="form-button-group">
                        <Button
                            className="btn-block"
                            size="lg"
                            onClick={() => setShowOffCanvasAdd(true)}
                        >Crea un'attività</Button>
                    </div>
                </div>
            </div>

            </Capsule>
            <OffcanvasAddActivity 
                show={showOffCanvasAdd}
                handleClose={user.agent.pendings.length ? () => setShowOffCanvasAdd(false) : null}
                showCoupon
            />
    </>;
}

export default WelcomeAgent;