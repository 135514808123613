import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoAdd, IoCheckmark, IoCloudUploadOutline, IoTrash } from 'react-icons/io5';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';
import PriceMatrix from 'components/PriceMatrix';
import { toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { AutocompleteOrganization } from 'components/autocomplete/Autocomplete';


function useOffCanvasManageCoupon({ manageCoupon, onUpdateCoupon }) {

    const [couponDetail, setCouponDetail] = useState();
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [organizationTypes, setOrganizationsTypes] = useState();

    useEffect(() => {
        if (!manageCoupon) {
            setCouponDetail();
            setIsConfirmDelete();
            return
        }
        axios.get(`/coupons/${manageCoupon.id}`, {
            params: {
                detailed: 1
            }
        })
        .then(res => setCouponDetail(res.data));
    }, [manageCoupon]);

    
    useEffect(() => {
        if (!manageCoupon || organizationTypes) return;
        axios.get('organizations-types')
        .then(res => setOrganizationsTypes(res.data));        
    }, [manageCoupon, organizationTypes]);

    const handleChangeInput = (e) => {
        const { name, value, type } = e.target;
        setCouponDetail({
            ...couponDetail,
            [name]: type === 'checkbox' ? !couponDetail[name] : value
        });
    }

    const handleSaveProduct = (e) => {
        e.preventDefault();
        axios.put(`coupons/${couponDetail.id}`, {
            code: couponDetail.code,
            description: couponDetail.description,
            discount: couponDetail.discount,
            expires_at: couponDetail.expires_at || null,
            max_products: couponDetail.max_products || null,
            organization_id: couponDetail.organization_id || null,
            organization_type_id: couponDetail.organization_type_id || null,
        })
        .then(res => {
            onUpdateCoupon(res.data);
            toast.success("Coupon aggiornato con successo!");
        })
        .catch(error => {
            toast.error("Errore durante il salvataggio.");
        });
    }

    const handleDelete = (confirm) => {
        if (!confirm) return setIsConfirmDelete(true);
        axios.delete(`coupons/${couponDetail.id}`)
        .then(res => {
            toast.success('Prodotto eliminato con successo');
            window.location.reload();
        });
    }

    return {
        couponDetail,
        handleChangeInput,
        handleSaveProduct,
        isConfirmDelete,
        handleDelete,
        organizationTypes,
    }
}


function OffcanvasManageCoupon(props) {

    const {
        manageCoupon, 
        handleClose, 
        coupons
    } = props;
    
    const { 
        couponDetail,
        handleChangeInput,
        handleSaveProduct,
        isConfirmDelete,
        handleDelete,
        organizationTypes,
    } = useOffCanvasManageCoupon(props);

    couponDetail && console.log(couponDetail);


    return (<>
        <Offcanvas placement="bottom" className="action-sheet" show={!!manageCoupon} onHide={handleClose}>
            <Offcanvas.Body>
                <a 
                    className="close-btn-modal text-25 back-btn-modal text-danger"
                    onClick={() => handleDelete(isConfirmDelete)}
                >
                    {isConfirmDelete ? <IoCheckmark /> : <IoTrash />}
                </a>
                <div className="section mt-3 text-center">
                    <h3>Modifica coupon</h3>
                </div>
                <OffCanvasCloseBtn handleClose={handleClose}/>

                {!couponDetail && <OffCanvasLoader />}

                <Form className="section px-2 py-2" onSubmit={handleSaveProduct}>

                    <div className="row">
                        <div className="col">
                            <div className="form-group boxed custom">
                                <AutocompleteOrganization 
                                    autoload={true}
                                    onChange={handleChangeInput}
                                    disabled={couponDetail?.organization_type_id}
                                    defaultValue={couponDetail?.organization_id}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label className="label-bordered" htmlFor='parent_id'>Categoria cliente</Form.Label>
                                    <Form.Select
                                        className='form-control'
                                        value={couponDetail?.organization_type_id ?? ''}
                                        id="organization_type_id"
                                        name="organization_type_id"
                                        onChange={handleChangeInput}
                                        disabled={couponDetail?.organization_id}
                                    >
                                        <option value="">TUTTE</option>
                                        {(organizationTypes ?? []).map(p => (
                                            <option key={p.id} value={p.id}>{p.description}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label className="label-bordered" htmlFor='description'>Descrizione coupon (non visibile ai clienti)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        minLength="3"
                                        maxLength="100"
                                        id="description"
                                        name="description"
                                        value={couponDetail?.description || ''}
                                        onChange={handleChangeInput}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label className="label-bordered" htmlFor='code'>Codice coupon</Form.Label>
                                    <Form.Control
                                        type="text"
                                        minLength="3"
                                        maxLength="20"
                                        id="code"
                                        name="code"
                                        value={couponDetail?.code || ''}
                                        onChange={handleChangeInput}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label className="label-bordered" htmlFor='discount'>% di sconto</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        max="100"
                                        id="discount"
                                        name="discount"
                                        value={couponDetail?.discount ?? ''}
                                        onChange={handleChangeInput}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label className="label-bordered" htmlFor='expires_at'>Scadenza</Form.Label>
                                    <Form.Control
                                        type="date"
                                        id="expires_at"
                                        name="expires_at"
                                        value={couponDetail?.expires_at ?? ''}
                                        onChange={handleChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label className="label-bordered" htmlFor='max_products'>Prodotti massimi</Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="max_products"
                                        name="max_products"
                                        value={couponDetail?.max_products ?? ''}
                                        onChange={handleChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group basic">
                        <button 
                            type="submit"
                            className="btn btn-primary btn-block"
                        >Modifica coupon</button>
                    </div>

                </Form>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasManageCoupon;