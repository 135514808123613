import { useMemo } from "react";

export default function useCartItems(products) {
    return useMemo(() => {
        if (!products) return null;
        const cartProducts = products.filter(p => p.is_selected);
        let total_time = 0;
        let total_price = 0;
        cartProducts.forEach(p => {
            total_time += (p.time || 0);
            total_price += +((['PRIMARY', 'EXTRA'].includes(p.type) ? p.price : p.price_matrix.price) ?? 0);
            if (p.is_priority) {
                total_price += (+p?.priority_price ?? 0);
            }
        });

        return {
            products: cartProducts.sort((a, b) => (a.type < b.type)),
            time: total_time,
            price: total_price,
            price_vat_included: total_price * 1.22
        }
    }, [products]);
}