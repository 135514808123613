import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { openUrl } from "utils/helpers";
import useEventListener from "./useEventListener";
import usePolling from "./usePolling";
import { useAuthValue } from "context/AuthContext";
import { toast } from "react-toastify";

export default function useOrder({
    pollingParams = {},
    orderId = null
} = {}) {

    const { user } = useAuthValue();
    const [couponData, setCouponData] = useState();
    const [callbackData, setCallbackData] = useState();
    const [isInProgress, setIsInProgress] = useState();
    const [isCartInProgress, setIsCartInProgress] = useState();
    const windowOpenRef = useRef(null);

    const { startPolling, stopPolling } = usePolling(() => {
        const [ data ] = callbackData;
        axios.get(`orders/${data.order.id}`, { params: pollingParams })
        .then(res => {
            if (res.data.status === 'AWAITING_PAYMENT') return;
            (res.data.status === 'PAYED' || res.data.status === 'PAYED_BALANCE') ? confirmOrder() : cancelOrder();
        });
    });

    useEventListener({
        'message': e => {
            if (!e.data || e.data.type !== 'WINDOW_CLOSE') return;
            if (e.data.message === 'CANCEL') cancelOrder();
        }
    }, [callbackData], window);
    
    const promiseOrder = (data, orderResolve, orderReject) => {
        if (couponData) {
            data.coupon_code = couponData.code;
        }
        return new Promise(resolve => {
            if (callbackData) return resolve(callbackData[0]);
            setIsCartInProgress(true);
            (orderId ? axios.post(`orders/${orderId}/complete-payment`) : axios.post('orders', data))
            .then(res => {
                setCallbackData([res.data, orderResolve, orderReject]);
                resolve(res.data);
                setIsCartInProgress(false);
            })
            .catch((res) => {
                setIsCartInProgress(false);
                orderReject(res);
                cancelOrder();
            });
        });
    }

    const applyCoupon = (coupon, n_products = 1, check_id = false) => {
        if (!coupon) {
            return setCouponData();
        }
        axios.get('coupons-check', { 
            params: {
                [check_id ? 'id' : 'code']: coupon,
                organization_id: user.agent.getSelectedOrganization().id,
                n_products
            }
        })
        .then(res => {
            if (!res.data.is_valid) {
                return toast.error('Coupon non valido o scaduto');
            }
            setCouponData(res.data)
        });
    }
    

    const placeOrder = (data) => {
        return new Promise((resolve, reject) => {
            promiseOrder(data, resolve, reject)
            .then(data => {
                if (!data.payment_url) {
                    blockRequest();
                    return resolve(data);
                };
                setIsInProgress(true);
                windowOpenRef.current = openUrl(data.payment_url);
                startPolling();
            })
            .catch(cancelOrder);
        });
    }

    const cancelOrder = () => {
        blockRequest();
        if (windowOpenRef.current) {
            windowOpenRef.current.close();
            windowOpenRef.current = null;
        }
        if (callbackData) {
            const [,, reject] = callbackData;
            reject();
        }
    }

    const blockRequest = () => {
        setIsInProgress(false);
        stopPolling();
    }

    const confirmOrder = () => {
        blockRequest();
        const [data, resolve] = callbackData;
        resolve(data);
    }

    const resetPaymentData = () => {
        cancelOrder();
        setCallbackData();
    }    

    return {
        placeOrder,
        isInProgress,
        isCartInProgress,
        cancelOrder,
        resetPaymentData,
        couponData,
        applyCoupon
    }
}