import axios from "axios";
import InputUpload from "components/InputUpload";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { IoDocument, IoInformationCircle, IoLockClosed, IoWarning } from "react-icons/io5";
import { Formik, Field, Form, useFormikContext, useField } from 'formik';
import { selectUploads } from "store/selectors";
import { useSelector } from "react-redux";
import { uploadsField } from "utils/helpers";
import RenderDetail from "components/RenderDetail";
import { toast } from "react-toastify";
import { RENDER_INSTRUCTIONS_FIELDS } from "constants";
import SelectOptions from "components/SelectOptions";
import { RenderInputUploadFurniture, RenderInputUploadPlan, RenderInputUploadReference } from "components/RenderUploadInputs";
import RenderViewBlock from "components/RenderViewBlock";
import { OffcanvasRenderView } from "components/offcanvas/OffCanvasRenderView";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import useEventListener from "hooks/useEventListener";
import OffCanvasLoader from "components/loader/OffCanvasLoader";
import { useAuthValue } from "context/AuthContext";
import SpinnerForButton from "./loader/SpinnerForButton";
import SkeletonDiv from "./loader/SkeletonDiv";
import Checkout from "./Checkout";
import imageAllCompleted from 'assets/img/all_completed.svg';
import imageWaiting from 'assets/img/waiting-image.svg';
import useOrder from "hooks/useOrder";
import { useDispatch } from "react-redux";
import { uploadReset } from "store/actions/uploads";

function InstructionForm({
    isUpdate,
    values,
    isSubmitting
}) {

    const { setFieldValue } = useFormikContext();
    const [frameTypeField] = useField('frame_type');

    useEffect(() => {
        if (frameTypeField.value !== 'SHUTTER') {
            setFieldValue('shutter_type', '');
            setFieldValue('shutter_box', '');
        }
    }, [frameTypeField.value, setFieldValue]);

    return (
        <Form className="section my-3">
        <RenderInputUploadPlan 
            disabled={isSubmitting || isUpdate}
            required
        />

        <RenderInputUploadReference 
            disabled={isSubmitting || isUpdate}
            required    
        />

        <div className="row mt-2">
            <div className="col ps-1 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <label className="label-bordered form-label">Altezza soffitto</label>
                        <Field 
                            id="ceiling_height" 
                            name="ceiling_height"
                            className="form-control"
                            required
                            disabled={isSubmitting || isUpdate}
                        />
                    </div>
                </div>
            </div>
            <div className="col pe-1">
                <div className="form-group boxed custom">
                    <div className="input-wrapper not-empty">
                        <label className="label-bordered form-label">Tapparelle/Scuri</label>
                        <Field
                            component="select"
                            id="frame_type" 
                            name="frame_type"
                            className="form-control form-select"
                            required
                            disabled={isSubmitting || isUpdate}
                        >
                            <SelectOptions options={RENDER_INSTRUCTIONS_FIELDS.frame_type}/>
                        </Field>
                    </div>
                </div>
            </div>
        </div>

        {/* Mostrare questo blocco se selezionano tapparelle */}
        {values.frame_type === 'SHUTTER' && (
            <div className="row">
                <div className="col ps-1 pe-0">
                    <div className="form-group boxed custom">
                        <div className="input-wrapper not-empty">
                            <label className="label-bordered form-label">Tipologia tapparella</label>
                            <Field
                                component="select"
                                id="shutter_type" 
                                name="shutter_type"
                                className="form-control form-select"
                                required
                                disabled={isSubmitting || isUpdate}
                            >
                                <SelectOptions options={RENDER_INSTRUCTIONS_FIELDS.shutter_type}/>
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="col pe-1">
                    <div className="form-group boxed custom">
                        <div className="input-wrapper not-empty">
                            <label className="label-bordered form-label">Tipologia cassone</label>
                            <Field
                                component="select"
                                id="shutter_box" 
                                name="shutter_box"
                                className="form-control form-select"
                                required
                                disabled={isSubmitting || isUpdate}
                            >
                                <SelectOptions options={RENDER_INSTRUCTIONS_FIELDS.shutter_box}/>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
        )}

        <div className="row">
            <div className="col ps-1 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <label className="label-bordered form-label">Tipologia riscaldamento</label>
                        <Field
                            component="select"
                            id="heating" 
                            name="heating"
                            className="form-control form-select"
                            required
                            disabled={isSubmitting || isUpdate}
                        >
                            <SelectOptions options={RENDER_INSTRUCTIONS_FIELDS.heating}/>
                        </Field>
                    </div>
                </div>
            </div>
            <div className="col pe-1">
                <div className="form-group boxed custom">
                    <div className="input-wrapper not-empty">
                        <label className="label-bordered form-label">Tipologia soffitto</label>
                        <Field
                            component="select"
                            id="ceiling" 
                            name="ceiling"
                            className="form-control form-select"
                            required
                            disabled={isSubmitting || isUpdate}
                        >
                            <SelectOptions options={RENDER_INSTRUCTIONS_FIELDS.ceiling}/>
                        </Field>
                    </div>
                </div>
            </div>
        </div>

        <div className='lh-1 text-warning fw-lighter'><IoWarning /> Se sono previste velette e contro soffitti è necessario caricare un PDF</div>
        {values.ceiling === 'BEAMS_ROOF_SLOPING' && <div className='lh-1 text-warning fw-lighter'><IoWarning /> Hai selezionato "<b>Con travi, tetto inclinato</b>", assicurati di allegare sopra anche i relativi prospetti e sezioni.</div>}

        <RenderInputUploadFurniture 
            disabled={isSubmitting}
        />

        <div className="mt-3 text-white">
            <div>
                <b>Indicare di seguito le seguenti informazioni:</b>
                <ul>
                    <li>Altezza e larghezza porte;</li>
                    <li>Dimensioni porte finestre;</li>
                    <li>Eventuali link di esempio a reference;</li>
                    <li>Altre informazioni che possono servirci.</li>
                </ul>
            </div>

            <h4 className="card-subtitle text-white text-12">NOTE AGGIUNTIVE:</h4>
            <div className="form-group boxed custom pt-0">
                <div className="input-wrapper">
                        <Field
                            component="textarea"
                            id="note" 
                            name="note"
                            className="form-control text-15"
                            rows="6"
                            required
                            disabled={isSubmitting || isUpdate}
                            placeholder="Esempio:&#13;&#10;Finestra soggiorno: H180 L90 - Altezza da terra 90&#13;&#10;Finestra bagno: H100 L60 - Altezza da terra 120&#13;&#10;Link lavabo: ..."
                        />
                </div>
            </div>
        </div>

        <Button
            className="btn-block mt-2 btn btn-primary text-uppercase"
            type="submit"
        >INVIA ISTRUZIONI</Button>

    </Form>
    );
}

function InformationStep({
    orderData,
    handleSaveInstruction
}) {

    const instruction = orderData.render.instruction;
    const { render_plan, render_reference, render_furniture } = useSelector(selectUploads);

    return (
        <Formik
            initialValues={{
                ceiling_height: instruction?.ceiling_height ?? '',
                frame_type: instruction?.frame_type ?? '',
                shutter_type: instruction?.shutter_type ?? '',
                shutter_box: instruction?.shutter_box ?? '',
                heating: instruction?.heating ?? '',
                ceiling: instruction?.ceiling ?? '',
                note: instruction?.note ?? '',
            }}
            onSubmit={async (values) => {
                axios.post('render-instructions', {
                    uploads: {
                        plan: uploadsField(render_plan, 'path'), 
                        reference: uploadsField(render_reference, 'path'), 
                        furniture: render_furniture ? uploadsField(render_furniture, 'path') : null
                    },
                    task_id: orderData.render.task_id,
                    ...values
                })
                .then(res => {
                    handleSaveInstruction(res.data);
                    toast.success('Istruzioni inviate con successo');
                })
            }}
        >
            {(props) => <InstructionForm 
                {...props}
                isUpdate={!!instruction?.id}
            />}
        </Formik>
    );
}

function NeedCheckout({
    orderData,
    reloadOrderData
}) {

    const { user } = useAuthValue();
    const {
        placeOrder,
        isInProgress,
        isCartInProgress,
        couponData,
        applyCoupon
    } = useOrder({
        orderId: orderData.id
    });

    const needToPay = orderData.total - orderData.deposit;

    const handlePlaceOrder = () => {
        placeOrder()
        .then(reloadOrderData);
    }
    
    return <>
        <div className="my-3 render-final-payment">

                <div className="section text-gray">
                <div className="bg-gray-dark p-2 pt-0 rounded" style={{marginTop: '90px'}}>
                    <div className="text-center">
                        <img className="w-100" src={imageAllCompleted} style={{maxWidth: '250px', marginTop: '-90px'}}/>
                    </div>
                    <div>
                        <IoInformationCircle className="text-20"/> Ora che tutte le viste sono state approvate provvederemo ad <b>elaborare in alta risoluzione</b> i render finali e a caricarli sull'applicazione.  
                        Nel frattempo ti chiediamo di <b>procedere al pagamento del servizio</b>, al cui totale è stato già sottratto l'acconto che era stato versato in fase di ordine.
                    </div> 
                </div>
            </div>

            <Checkout 
                cartItems={{
                    price: needToPay,
                    price_vat_included: (needToPay * 1.22)
                }}
                isInProgress={isInProgress}
                isCartInProgress={isCartInProgress}
                completeCheckout={handlePlaceOrder}
                couponData={couponData}
                applyCoupon={applyCoupon}
            />
            <div className="text-gray text-14 section ms-2 mt-1"><i>*Una volta effettuato il pagamento <b>dovrai attendere che il materiale definitivo venga consegnato</b> e ti compaia in questa sezione.</i></div>
        </div>
    </>;
}

function DeliverySection({
    orderData,
    onUpdateRender,
    onTaskCompleted
}) {

    const { render } = orderData;
    const dispatch = useDispatch();
    const { user } = useAuthValue();
    const [isLoading, setIsLoading] = useState(false);
    const [linkVirtualTour, setLinkVirtualTour] = useState('');

    const uploads = useSelector(selectUploads);

    const renderViewsUploads = Object.entries(uploads)
        .filter(([group, uploads]) => group.includes('render_view_') && Object.keys(uploads).length > 0)
        .map(([_, uploads]) => Object.values(uploads))
        .flat();

    const emotionalViewsUploads = Object.entries(uploads)
        .filter(([group, uploads]) => group.includes('render_emotional_view_') && Object.keys(uploads).length > 0)
        .map(([_, uploads]) => Object.values(uploads))
        .flat();
    
    const planUpload = uploadsField(uploads.delivery_plan ?? {}, 'path');

    const handleSendUpload = () => {
        setIsLoading(true);
        axios.post(`/render-views/save-delivery?delivery=1`, {
            task_id: render.task_id,
            ...(renderViewsUploads.length > 0 && { 
                views: renderViewsUploads.map(u => ({
                    id: u.metadata.view_id, 
                    path: u.path
                }))
            }),
            ...(emotionalViewsUploads.length > 0 && { 
                emotional_views: emotionalViewsUploads.map(u => ({
                    index: u.metadata.emotional_view_index,
                    path: u.path
                }))
            }),
            ...(render.plan && planUpload.length > 0 && { plan: planUpload[0] }),
            ...(render.virtual_tour && linkVirtualTour && { virtual_tour: linkVirtualTour })
        })
        .then(res => {
            dispatch(uploadReset('*'));
            onUpdateRender(res.data);
            setIsLoading(false);
        });
    }

    const handleDoneTask = () => {
        setIsLoading(true);
        axios.put(`tasks/${render.task_id}`, {
            update_role: 'editor',
        })
        .then(() => {
            toast.success('Task completato con successo');
            onTaskCompleted && onTaskCompleted(render.task_id);
        });
    }

    const allIsDelivery = render.views.filter(v => !v.delivery).length === 0 && 
        (!render.plan || render.delivery?.plan) && 
        (!render.emotional_view || render.emotional_view === render.delivery?.emotional_views?.length) &&
        (!render.virtual_tour || render.delivery?.virtual_tour);

    return <>
        <div className="section my-3 text-white">

            {user.is_staff && render.task_status !== 'DONE' && <div className="bg-gray-dark p-2 mb-2 rounded">
                <div className="text-gray">
                    <IoInformationCircle className="text-20"/> Ora che tutte le viste sono state approvate puoi procedere al <b>caricamento dei render in alta qualità</b>. Il cliente li potrà visionare non appena avrà effettuato il pagamento.
                </div>
            </div>}

            {render.virtual_tour && (
                <div className="form-group boxed">
                    <div className="input-wrapper mb-2">
                        {render.delivery?.virtual_tour ? 
                        <Button 
                            className="w-100" 
                            variant="light"
                            as="a"
                            href={render.delivery?.virtual_tour}
                            target="_blank"
                        >VISUALIZZA VIRTUAL TOUR</Button> :
                        <input
                            type="text"
                            className="form-control"
                            style={{ background: '#3a3a3a' }}
                            value={linkVirtualTour}
                            name="virtual_tour"
                            placeholder="Inserisci il link del virtual tour"
                            onChange={(e) => setLinkVirtualTour(e.target.value)}
                        />}
                    </div>
                </div>
            )}

            <div className='d-grid grid-render mb-2 delivery'>
                {render.views.map(view => {
                    const label = `${view.render_ambient.label} - Ambient ${view.ambient_index} - Vista ${view.view_index}`;
                    return view.delivery ? 
                        <div key={`render_view_${view.id}`} >
                            <div 
                                className="custom-file-upload bg-amazon upload-no-bb" 
                                style={{ backgroundImage: `url(${view.delivery.url})`}}
                            />
                            <ul className={`listview image-listview uploads-list overflow-hidden no-border`}>
                                <li className={`position-relative`}>
                                    <span className="item py-0">
                                        <IoDocument className="me-1"/>
                                        <div className="in">
                                            <div className="flex-fill pe-1 text-14 lh-1">
                                                {label}
                                            </div>
                                            <a href={view.delivery.url} target="_blank" className="text-12 active">
                                                SCARICA
                                            </a>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div> : 
                        <InputUpload
                            key={`render_view_${view.id}`} 
                            gkey={`render_view_${view.id}`}
                            label={label}
                            limit={1}
                            hideLabelOnLimit={false}
                            metadata={{
                                view_id: view.id
                            }}
                        />
                })}
                {new Array(render.emotional_view).fill(null).map((_, i) => (
                    render.delivery?.emotional_views?.[i] ? 
                        <div key={`emotional_view_${i}`} >
                            <div 
                                className="custom-file-upload bg-amazon upload-no-bb" 
                                style={{ backgroundImage: `url(${render.delivery?.emotional_views?.[i]})`}}
                            />
                            <ul className={`listview image-listview uploads-list overflow-hidden no-border`}>
                                <li className={`position-relative`}>
                                    <span className="item py-0">
                                        <IoDocument className="me-1"/>
                                        <div className="in">
                                            <div className="flex-fill pe-1 text-14 lh-1">
                                                Vista emozionale {i + 1}
                                            </div>
                                            <a href={render.delivery?.emotional_views?.[i]} target="_blank" className="text-12 active">
                                                SCARICA
                                            </a>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div> : 
                        <InputUpload
                            key={`emotional_view_${i}`} 
                            gkey={`render_emotional_view_${i}`}
                            label={`Vista emozionale ${i + 1}`}
                            limit={1}
                            hideLabelOnLimit={false}
                            metadata={{
                                emotional_view_index: i
                            }}
                        />
                ))}
                {render.plan && (
                    render.delivery?.plan ?
                    <div>
                        <div
                            className="custom-file-upload bg-amazon upload-no-bb" 
                            style={{ backgroundColor: '#3A3A3A' }}
                        >
                            <label>
                                <span>
                                    <strong className="text-gray">
                                        <i>Planimetria 3D</i>
                                    </strong>
                                </span>
                            </label>
                        </div>
                        <ul className={`listview image-listview uploads-list overflow-hidden no-border`}>
                            <li className={`position-relative`}>
                                <span className="item py-0">
                                    <IoDocument className="me-1"/>
                                    <div className="in">
                                        <div className="flex-fill pe-1 text-14 lh-1">
                                            File
                                        </div>
                                        <a href={render.delivery.plan} target="_blank" className="text-12 active">
                                            SCARICA
                                        </a>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div> : 
                    <InputUpload
                        gkey="delivery_plan"
                        label={`Planimetria 3D`}
                        limit={1}
                        hideLabelOnLimit={false}
                        accept="application/pdf"
                    />
                )}
            </div>
            
            {!user.is_agent && render.task_status !== 'DONE' && <>
                {allIsDelivery ? 
                    <Button
                        onClick={handleDoneTask}
                        className={`btn-block mt-2 btn btn-primary text-uppercase ${isLoading ? 'disabled' : ''}`}
                        type="button"
                    >
                        <SpinnerForButton show={isLoading} />CONSEGNA PROGETTO
                    </Button> :
                    <Button
                        onClick={handleSendUpload}
                        className={`btn-block mt-2 btn btn-primary text-uppercase ${((renderViewsUploads.length > 0 || emotionalViewsUploads.length > 0 || planUpload.length > 0 || !!linkVirtualTour) && !isLoading) ? '' : 'disabled'}`}
                        type="button"
                    >
                        <SpinnerForButton show={isLoading} />SALVA
                    </Button>
                }
            </>}
        </div>
    </>;
}

function DeliveryStep({
    orderData,
    reloadOrderData,
    onUpdateRender,
    onTaskCompleted
}) {

    const { user } = useAuthValue();
    
    if (user.is_agent) {
        if (orderData.deposit < orderData.total) return <NeedCheckout 
            orderData={orderData}
            reloadOrderData={reloadOrderData}
        />

        if (orderData.render.task_status !== 'DONE') {
            return (
                <div className="section text-gray text-center my-2">
                    <div className="bg-gray-dark p-2 pb-0 rounded">
                        <div className="max-width-600 m-auto">
                            <div>
                                <IoInformationCircle className="text-20"/> Grazie per aver effettuato il pagamento. Stiamo attualmente <b>lavorando per renderizzare i file finali in alta qualità</b>, appena saranno pronti riceverai una notifica e li potrai scaricare da questa sezione dell'applicazione. 
                            </div> 
                            <div>
                                <img className="w-100" src={imageWaiting} style={{maxWidth: '150px'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return <DeliverySection 
        orderData={orderData}
        onUpdateRender={onUpdateRender}
        onTaskCompleted={onTaskCompleted}
    />;
}

function FeedbackStep({
    orderData,
    onUpdateView,
    onUpdateViews
}) {

    const views = orderData.render.views;
    const [isSendToCustomer, setIsSendToCustomer] = useState(false);
    const [showRenderViewId, dispatchShowRenderView] = useOffCanvasReducer();

    const { user } = useAuthValue();
    const canSendToCustomer = views.filter(v => v.last_revision?.status === 'WAITING_EDITOR' && v.last_revision?.image).length > 0;

    const handleSendToCustomer = () => {
        setIsSendToCustomer(true);
        axios.post('render-views/send-revision', {
            task_id: orderData.render.task_id
        })
        .then(res => {
            setIsSendToCustomer(false);
            onUpdateViews(res.data);
        });
    }

    return <>
        <div className="section my-3">
            <div className='d-grid grid-render mb-2'>
                {views.map(view => (
                    <RenderViewBlock 
                        key={view.id}
                        view={view}
                        handleViewClick={() => dispatchShowRenderView(view.id)}
                    />
                ))}
            </div>
            {user.is_editor && (
                <Button
                    className={`btn-block btn btn-primary text-uppercase ${(canSendToCustomer && !isSendToCustomer) ? '' : 'disabled'}`}
                    type="button"
                    onClick={handleSendToCustomer}
                >
                    <SpinnerForButton show={isSendToCustomer}/> INVIA AL CLIENTE
                </Button>
            )}
        </div>
        <OffcanvasRenderView 
            renderViewId={showRenderViewId}
            handleClose={() => dispatchShowRenderView(false)}
            orderData={orderData}
            onUpdateView={onUpdateView}
        />
    </>;
}

export default function RenderTabs({
    orderData,
    setOrderData,
    onTaskCompleted
}) {

    const [tab, setTab] = useState();
    const [tabIsLoading, setTabIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleTabChange = (tab) => setTab(tab);
    const handleSaveInstruction = (instruction) => {
        setOrderData({
            ...orderData,
            render: {
                ...orderData.render,
                instruction: instruction
            }
        });
    }

    const onUpdateView = (view) => {
        const newViews = orderData.render.views.map(v => v.id === view.id ? {
            ...view,
            last_revision: view.revisions.length > 0 ? view.revisions[view.revisions.length - 1] : null
        } : v);
        const isDelivery = newViews.filter(v => v.last_revision?.status !== 'APPROVED').length === 0;
        setOrderData({
            ...orderData,
            render: {
                ...orderData.render,
                status: isDelivery ? 'delivery' : 'feedback',
                views: newViews
            }
        });
        if (!isDelivery) return;
        setTab('delivery');
    }

    const onUpdateViews = (views) => {
        setOrderData({
            ...orderData,
            render: {
                ...orderData.render,
                views: views
            }
        });
    
    }

    const onUpdateRender = (render) => {
        setOrderData({
            ...orderData,
            render: {
                ...orderData.render,
                ...render
            }
        });
    }

    const reloadOrderData = () => {
        setTabIsLoading(true);
        axios.get(`orders/${orderData.id}`, {
            params: {
                detailed: 1
            }
        })
        .then(res => {
            console.log(res.data);
            setOrderData({
                ...orderData,
                ...res.data
            });
            setTabIsLoading(false);
        });
    }

    useEffect(() => {
        if (!orderData || !tab) return;
        if (tab === 'information' && orderData.render.status !== 'information' && !orderData.render.instruction) {
            setTabIsLoading(true);
            axios.get('render-instructions', {
                params: {
                    task_id: orderData.render.task_id
                }
            })
            .then(res => {
                setOrderData({
                    ...orderData,
                    render: {
                        ...orderData.render,
                        instruction: res.data
                    }
                });
                setTabIsLoading(false);
            });
            return
        }
        if (
            tab === 'feedback' && 
            (!orderData.render.views || (orderData.render.views && orderData.render.status === 'delivery' && !orderData.render.views[0]?.last_revision))
        ) {
            setTabIsLoading(true);
            axios.get('render-views', {
                params: {
                    task_id: orderData.render.task_id
                }
            })
            .then(res => {
                setOrderData({
                    ...orderData,
                    render: {
                        ...orderData.render,
                        views: res.data.map(v => ({
                            ...(orderData.render.views && orderData.render.views.find(vs => vs.id === v.id)),
                            ...v,
                        }))
                    }
                });
                setTabIsLoading(false);
            });
            return
        }
    }, [tab, orderData]);

    useEffect(() => {
        if (!orderData) return setTabIsLoading(true);
        if (tab) return;
        setTab(orderData.render.status);
        setTabIsLoading(false);
    }, [orderData]);

    return (
        <div className="section render-section">
            <Card>
                <Card.Header className="p-0">
                    <ul className="nav nav-tabs lined" role="tablist" style={{ backgroundColor: '#222222', borderRadius: '0.375rem 0.375rem 0 0' }}>
                        <li className="nav-item">
                            <a
                                className={`nav-link fw-bold ${tab === 'information' ? 'active' : ''}`}
                                onClick={() => handleTabChange('information')}
                                data-bs-toggle="tab"
                                role="tab"
                            >
                                {orderData ? 'INFORMAZIONI' : <SkeletonDiv w={80} />}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link fw-bold ${tab === 'feedback' ? 'active' : ''} ${['feedback', 'delivery'].includes(orderData?.render.status) ? '' : 'disabled opacity-50'}`}
                                onClick={() => handleTabChange('feedback')}
                                data-bs-toggle="tab"
                                role="tab"
                            >
                                {orderData ? (
                                    <>
                                        {['feedback', 'delivery'].includes(orderData?.render.status) ? null : <IoLockClosed />}
                                        FEEDBACK
                                    </>
                                ) : (
                                    <SkeletonDiv w={80} />
                                )}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`placeholder-glow nav-link fw-bold ${tab === 'delivery' ? 'active' : ''} ${!orderData || (orderData?.render.status === 'delivery') ? '' : 'disabled opacity-50'}`}
                                onClick={() => handleTabChange('delivery')}
                                data-bs-toggle="tab"
                                role="tab"
                            >
                                {orderData ? (
                                    <>
                                        {!orderData || orderData?.render.status !== 'delivery' ? <IoLockClosed /> :  ''}
                                        {orderData.deposit < orderData.total ? 'PAGAMENTO' : 'CONSEGNA'}
                                    </>
                                ) : (
                                    <SkeletonDiv w={80} />
                                )}
                            </a>
                        </li>
                    </ul>
                </Card.Header>
                <Card.Body className="p-0 position-relative">
                    {tabIsLoading ? 
                        <div style={{ minHeight: '350px' }}>
                            <OffCanvasLoader /> 
                        </div> : <>
                        {tab === 'information' && (
                        !orderData.render.instruction ? <InformationStep 
                            orderData={orderData}
                            handleSaveInstruction={handleSaveInstruction}
                        /> : <RenderDetail 
                            render={orderData.render}
                            handleSaveInstruction={handleSaveInstruction}
                        />
                        )}
                        {tab === 'feedback' && orderData.render.views && <FeedbackStep 
                            orderData={orderData}
                            onUpdateViews={onUpdateViews}
                            onUpdateView={onUpdateView}
                        />}
                        {tab === 'delivery' && <DeliveryStep
                            orderData={orderData}
                            reloadOrderData={reloadOrderData}
                            onUpdateRender={onUpdateRender}
                            onTaskCompleted={onTaskCompleted}
                        />}
                    </>} 
                </Card.Body>
            </Card>
        </div>
    );
}